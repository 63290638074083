<ng-container *ngIf="!utils.isCollectionNullOrEmpty(_stocks)">

  <dx-data-grid
    [dataSource]="_stocks"
    keyExpr="id"
    height="100%"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="false"
    [showRowLines]="true"
    [showBorders]="true"
    (onCellPrepared)="onCellPrepared($event)"
    [columnWidth]="160"
    #grid
  >
    <dxi-column dataField="id" alignment="center" caption="Actions"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="zdsLibelle" alignment="left" caption="Emplacement"
                [allowGrouping]="false" [allowFiltering]="false" cellTemplate="zdsCellTemplate"></dxi-column>
    <dxi-column dataField="lotArticle" alignment="left" caption="Lot"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="article" alignment="left" caption="Article"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="fournisseur" alignment="left" caption="Fournisseur"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="dateEntree" alignment="left" caption="Date Entrée" dataType="date"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="dlc" alignment="left" caption="DLC" dataType="date"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="quantiteUS" alignment="left" caption="Quantité en unité de stock" cellTemplate="qteUSCellTemplate"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="quantiteKg" alignment="left" caption="Quantité en kilo" cellTemplate="qteKgCellTemplate"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="prixUnitaireKg" alignment="left" caption="PU H.T au kilo" cellTemplate="puHTKiloCellTemplate"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="prixUnitaireUS" alignment="left" caption="PU H.T en US" cellTemplate="puHTUSCellTemplate"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="prixUnitaire" alignment="left" caption="PU H.T en UF" cellTemplate="puHTUFCellTemplate"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="modifiePar" alignment="left" caption="Modifié par"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="dateDerniereModification" alignment="left" caption="Date de dernière modification" dataType="date"
                [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="cursor">
      <yo-cell-button
        (click)="openDialogSortie(cell.row.data)"
        [yoTooltipShowDelay]="1500"
        [yoMaxWidth]="true"
        [yoWidthPercent]="30"
        [yoTextAlign]="'center'"
        [yoIconClass]="'fas fa-minus'"
        [title]="'Procéder manuellement à une sortie partielle ou totale d\'un lot de matières premières'"
        [yoDisabled]="true"
        tooltipPosition="right"
        showDelay="1000"
      ></yo-cell-button>
      <yo-cell-button
        (click)="openDialogEditLot(cell.row.data)"
        [yoTooltipShowDelay]="1500"
        [yoMaxWidth]="true"
        [yoWidthPercent]="30"
        [yoTextAlign]="'center'"
        [yoIconClass]="'fa fa-edit'"
        [title]="'Modifier le lot'"
        [yoDisabled]="true"
        tooltipPosition="right"
        showDelay="500"
      ></yo-cell-button>
    </div>
    <div *dxTemplate="let cell of 'zdsCellTemplate'">
      <div>
        <i class="fas fa-industry mg-r-5"></i>{{cell.row.data.udpLibelle}}
      </div>
      <div class="mg-l-10 mg-t-5">
        <i class="fas fa-cubes mg-r-5"></i>{{cell.row.data.zdsLibelle}}
      </div>
    </div>
    <div *dxTemplate="let cell of 'qteUSCellTemplate'">
      {{cell.row.data.quantiteUS | number:'1.4-4' }} / {{cell.row.data.uniteDeStockage}}
    </div>
    <div *dxTemplate="let cell of 'qteKgCellTemplate'">
      {{ cell.row.data.quantiteKg | number:'1.4-4' }} Kg
    </div>
    <div *dxTemplate="let cell of 'puHTKiloCellTemplate'">
      {{ cell.row.data.prixUnitaireKg | currency:'EUR':'symbol':'1.4-4' }}
    </div>
    <div *dxTemplate="let cell of 'puHTUSCellTemplate'">
      {{cell.row.data.prixUnitaireUS | currency:'EUR':'symbol':'1.4-4' }} / {{cell.row.data.uniteDeStockage}}
    </div>
    <div *dxTemplate="let cell of 'puHTUFCellTemplate'">
      {{cell.row.data.prixUnitaire | currency:'EUR':'symbol':'1.4-4' }} / {{cell.row.data.uniteFacturation}}
    </div>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} résultat(s)">
    </dxo-pager>

  </dx-data-grid>

</ng-container>

<yo-dialog-modification-lots></yo-dialog-modification-lots>

