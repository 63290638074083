<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Etape de contrôle des fiches techniques</h1>
    <div class="item-wizard">
      <p>
        Cette étape vous permet de contrôler le poids net total des fiches techniques des plats exprimé en grammes (pour 1 UT) que vous avez sélectionné précédemment.
        Pour modifier un poids net, il suffit de cliquer sur une cellule présentant un champ de saisie puis d'y inscrire une valeur, la valeur sera automatiquement mise à jour.
      </p>
      <div class="row mg-t-12">
        <div class="col-lg-12 container-table">
          <dx-data-grid
            [dataSource]="produits"
            keyExpr="id"
            width="100%"
            height="100%"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true">
            <dxi-column dataField="libelle" caption="Nom du plat" [allowFiltering]="false" cellTemplate="libelleTemplate"></dxi-column>
            <dxi-column *ngFor="let d of declinaisons"
                        cellTemplate="dCellTemplate"
                        [width]="300"
                        [dataField]="d.id"
                        [caption]="d?.libelle">
            </dxi-column>
            <div *dxTemplate="let cell of 'libelleTemplate'">
              {{ cell.value }} <i *ngIf="containsWrongValues(cell.row.data.id)" class="fa-solid fa-triangle-exclamation" title="Un ou plusieurs poids net(s) ne sont pas renseignés ou ont une valeur égale à 0 ou à 1"></i>
            </div>
            <div class="flex-wrap" *dxTemplate="let cell of 'dCellTemplate'">
              <div *ngIf="findTotalNetUT(cell.row.data.id, cell.column.dataField) !== undefined; let totalNetUT">
                <input [class.warning-total]="findTotalNetUT(cell.row.data.id, cell.column.dataField) === 0" [class.light-warning-total]="findTotalNetUT(cell.row.data.id, cell.column.dataField) === 1" type="number" (change)="updateTotalNetUT($event, cell.row.data.id, cell.column.dataField)" [value]="findTotalNetUT(cell.row.data.id, cell.column.dataField)" /> grammes
              </div>
            </div>

            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[20, 50, 100]"
              [showNavigationButtons]="false"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} plat(s)">
            </dxo-pager>

          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 7/13 : Etape de contrôle des fiches techniques - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>

