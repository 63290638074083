<dx-popup
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [title]="data?.title"
  [width]="700"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view
      width="100%"
      height="100%">
      <form (ngSubmit)="saveContratMenuConviveEquipe()" novalidate>
        <div class="row mg-b-15">
        <div class="col-lg-4">
          <label class="label-select">Type</label>
        </div>
        <div class="col-lg-8">
          <dx-lookup
            [(value)]="selectedFabrication"
            (onValueChanged)="onChangeTache($event)"
            [items]="tacheFabricationList"
            [disabled]="isTypeDeFabricationDisabled()"
            placeholder="Type affecté à l'équipe"
            valueExpr="id"
            displayExpr="libelle"
            searchExpr="libelle"
            filter="true"
          >
          </dx-lookup>
        </div>
      </div>
        <div class="row mg-b-15" *ngIf="displayEquipes">
        <div class="col-lg-4">
          <label class="label-select">Équipe</label>
        </div>
        <div class="col-lg-8">
          <dx-lookup
            [(value)]="selectedUdpEquipe"
            [dataSource]="dataSourceTeams"
            itemTemplate="itemTpl"
            placeholder="Choisir l'équipe de production"
            valueExpr="id"
            displayExpr="equipe.libelle"
            searchExpr="equipe.libelle"
            groupTemplate="groupTpl"
            [grouped]="true"
            filter="true"
            (onValueChanged)="onChangeEquipe($event)"
          >
            <dxo-drop-down-options [showTitle]="false">
              <div *dxTemplate="let item of 'groupTpl'">
                <span [title]="item.key"><i class="fas fa-industry mg-r-5"></i> {{ item.key }}</span>
              </div>
              <div *dxTemplate="let item of 'itemTpl'">
                <span [title]="item.equipe.libelle"><i class="fas fa-users mg-r-5"></i> {{ item.equipe.libelle }}</span>
              </div>
            </dxo-drop-down-options>
          </dx-lookup>
        </div>
      </div>
      <div class="row mg-b-15" *ngIf="displayRepas">
          <div class="col-lg-4">
            <label>Repas</label>
          </div>
          <div class="col-lg-8">
          <dx-lookup
            [(value)]="repasSelected"
            [items]="repas"
            placeholder="Repas associé à l'équipe"
            valueExpr="id"
            displayExpr="libelle"
            searchExpr="libelle"
            filter="true"
          >
          </dx-lookup>
        </div>
      </div>
      <div class="row mg-b-15">
        <div class="col-lg-12">
          <yo-cmc-equipes-jours [contratMenuConvive]="contratMenuConvive"
                                [cmcEquipe]="cmcEquipe"
                                [udpEquipeList]="udpEquipeList"></yo-cmc-equipes-jours>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-right">
          <button pButton
                  title="Enregistrer"
                  type="submit"
                  icon="fas fa-save"
                  class="p-button-success"
                  [disabled]="!gds.canModify(cmcEquipe)"></button>
          <button
            class="mg-l-5 p-button-secondary"
            pButton type="button"
            (click)="closeDialog()"
            icon="fas fa-times">
          </button>
        </div>
      </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

