// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.4-rc-9287(7d660504d5)-C22/05/2023-18:40:51-B22/05/2023-18:43:48' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.4-rc-9287(7d660504d5)-C22/05/2023-18:40:51-B22/05/2023-18:43:48",
  branch: "master",
  latestTag: "5.4-rc",
  revCount: "9287",
  shortHash: "7d660504d5",
  longHash: "7d660504d51aac7ee105db5c4c50385de41f3bfc",
  dateCommit: "22/05/2023-18:40:51",
  dateBuild: "22/05/2023-18:43:48",
  buildType: "Ansible",
  } ;
