<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          (click)="createRule()"
          [disabled]="!canEdit()"
          title="Créer une règle de regroupement">
        </button>
        <span *ngIf="prestations && prestations.length === 0">
          <i class="fas fa-exclamation-triangle danger-color space"
             title="Veuillez configurer le point de livraison client actuel à un menu (Offre alimentaires / Prestation / Points de livraison) afin de pouvoir récupérer les prestations et les régimes nécessaires pour la création de règles de regroupement">
          </i>
        </span>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <button
        pButton
        type="button"
        icon="fas fa-trash"
        class="cta-delete"
        (click)="deleteValues()"
        [disabled]="!canEdit() || !selectedRows.length"
        title="Supprimer le(s) règle(s) sélectionnée(s)">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="rules"
  [allowColumnResizing]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(282)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  (onCellClick)="onCellClick($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowPrepared)="onRowPrepared($event)"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column caption="Actions" cellTemplate="actionsCellTemplate" [width]="120" [fixed]="true"
              fixedPosition="left"></dxi-column>
  <dxi-column *ngFor="let prestation of prestations"
              [width]="300"
              dataField="libelle"
              [caption]="'Prestation ' + prestation.libelle">
    <dxi-column *ngFor="let regime of prestation.regimes"
                dataField="libelle"
                cellTemplate="regimeCellTemplate"
                [name]="prestation"
                [caption]="'Régime ' + regime.libelle">
    </dxi-column>
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <div class="text-center">
      <button
        pButton
        type="button"
        icon="fas fa-toggle-on"
        class="mg-r-10 cursor"
        (click)="toggleRow(cell.data.id)"
        [disabled]="!canEdit()"
        title="Activer/Désactiver tous les régimes">
      </button>
    </div>
  </div>
  <div
    [title]="getToolTipPrestation(cell)"
    *dxTemplate="let cell of 'regimeCellTemplate'">
    <span [title]="getToolTipPrestation(cell)">&nbsp;</span>
  </div>

</dx-data-grid>

<div class="d-flex mg-t-10">

  <div class="mr-auto ">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <span class="mg-r-5 font-12">N'oubliez pas d'enregistrer votre travail</span>
  </div>

  <div id="ctas">
    <button pButton
            title="Enregistrer"
            type="submit"
            icon="fas fa-save"
            class="p-button-success"
            (click)="save()"
            [disabled]="!canEdit"></button>
  </div>
</div>
