<p-card>
  <div class="card-size">

    <dx-toolbar class="yo-toolbar">
      <dxi-item location="before">
        <div class="d-flex flex-row align-items-center">
          <div class="mg-l-5 mg-r-5">
            <button
              pButton
              class="mg-r-10 cursor"
              icon="fas fa-cog"
              label="GÉRER LA GRILLE"
              pTooltip="Gérer la grille"
              (click)="openCreateRowParametrage()"
              [disabled]="!hasIDistri"
            >
            </button>
          </div>
        </div>
      </dxi-item>

    </dx-toolbar>

    <dx-data-grid
      [dataSource]="gridParametrage"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(382)"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      (onCellClick)="onCellClick($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onRowPrepared)="onRowPrepared($event)"
      #grid>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxi-column dataField="mcpLabel" caption="Modèle de conditionnement plat" [width]="250"  [groupIndex]="0"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="170"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="Variante" [width]="170"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column *ngFor="let prestation of prestationList"
                  dataField="prestation.id"
                  cellTemplate="prestationCellTemplate"
                  [caption]="prestation.libelle">
      </dxi-column>

      <dxo-summary>
        <dxi-total-item
          *ngFor="let prestation of prestationList"
          name="SelectedRowsSummary"
          showInColumn="prestation">
        </dxi-total-item>
      </dxo-summary>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} conditionnements plc">
      </dxo-pager>

      <div [class]="cell.row.data.prestations[cell.columnIndex][cell.row.data.id].actif ? 'prestation-enabled' : 'prestation-disabled'"
           [title]="getToolTipPrestation(cell)"
           *dxTemplate="let cell of 'prestationCellTemplate'">
        <span [title]="getToolTipPrestation(cell)">&nbsp;</span>
      </div>

    </dx-data-grid>

    <div class="d-flex mg-t-10">

      <div class="mr-auto ">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        <span class="mg-r-5 font-12">N'oubliez pas d'enregistrer votre travail</span>
      </div>

      <div id="ctas">
        <button pButton
                title="Enregistrer"
                type="submit"
                icon="fas fa-save"
                class="p-button-success"
                (click)="save()"
                [disabled]="!hasIDistri">
        </button>
      </div>
    </div>

  </div>
</p-card>

<yo-mp-row-parametrage-plc></yo-mp-row-parametrage-plc>
