<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Configuration du regroupement des conditionnements</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de configurer la manière dont vous allez regrouper vos différents regroupements pour chacun de vos
        points de livraison client.
      </p>
    </div>
    <div class="row mg-t-10">
      <div class="col-lg-12 container-table">
        <ng-container *ngFor="let plc of pointsLivraisons">
          <label class="badge badge-secondary badge-plc">{{ plc.libelle }}</label>
          <ng-container *ngIf="rulesByPlcs">
            <div class="container-cta-rules text-right mg-b-5">
              <button class="add-cta-rules mg-r-5" (click)="createRule(plc.id)"><i class="fas fa-plus"></i></button>
              <button [disabled]="disableSaveByPlcs[plc.id]" class="save-cta-rules mg-r-5" (click)="saveRules(plc.id)"><i class="fas fa-save"></i></button>
              <button class="delete-cta-rules" (click)="deleteValues(plc.id)"><i class="fas fa-trash"></i></button>
            </div>
            <div class="mg-b-15">
              <dx-data-grid
              [dataSource]="rulesByPlcs ? rulesByPlcs[plc.id] : []"
              [allowColumnResizing]="true"
              keyExpr="id"
              height="150"
              width="100%"
              [hoverStateEnabled]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [(selectedRowKeys)]="selectedRows[plc.id]"
              (onCellClick)="onCellClick($event)"
              (onCellPrepared)="onCellPrepared($event)"
              (onRowPrepared)="onRowPrepared($event)"
              #parts>
              <dxo-selection
                [selectAllMode]="allMode"
                [showCheckBoxesMode]="checkBoxesMode"
                mode="multiple"
              ></dxo-selection>
              <dxi-column caption="Actions" cellTemplate="actionsCellTemplate" [width]="120" [fixed]="true"
                          fixedPosition="left"></dxi-column>
              <dxi-column *ngFor="let prestation of prestationsRegimesByPlcs[plc.id]"
                          [width]="300"
                          dataField="libelle"
                          [caption]="'Prestation ' + prestation.libelle">
                <dxi-column *ngFor="let regime of prestation.regimes"
                            dataField="libelle"
                            cellTemplate="regimeCellTemplate"
                            [name]="prestation"
                            [caption]="'Régime ' + regime.libelle">
                </dxi-column>
              </dxi-column>

              <div *dxTemplate="let cell of 'actionsCellTemplate'">
                <div class="text-center">
                  <button
                    pButton
                    type="button"
                    icon="fas fa-toggle-on"
                    class="mg-r-10 cursor"
                    (click)="toggleRow(plc.id, cell.data.id)"
                    pTooltip="Activer/Désactiver tous les régimes">
                  </button>
                </div>
              </div>
              <div
                [title]="getToolTipPrestation(cell)"
                *dxTemplate="let cell of 'regimeCellTemplate'">
                <span [title]="getToolTipPrestation(cell)">&nbsp;</span>
              </div>

            </dx-data-grid>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 11/13 : Configuration du regroupement des conditionnements - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
