import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionClientsRoutingModule} from './gestion-clients-routing.module';
import {SharedModule} from '../shared/shared.module';
import {ClientFicheIdentiteComponent} from './clients/client/fiche-identite/client-fiche-identite.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {GridClientsComponent} from "./grille-clients/grid-clients.component";
import {ClientComponent} from "./clients/client.component";
import {PointDeLivraisonComponent} from "./points-de-livraison/point-de-livraison/point-de-livraison.component";
import {PointDeLivraisonFicheIdentiteComponent} from "./points-de-livraison/point-de-livraison/fiche-identite/point-de-livraison-fiche-identite.component";
import {PointDeLivraisonConditionnementComponent} from "./points-de-livraison/point-de-livraison/conditionnement/point-de-livraison-conditionnement.component";
import {DxDataGridModule, DxLookupModule, DxPopupModule, DxToolbarModule} from "devextreme-angular";
import {LiaisonPlcMcPlcComponent} from "./points-de-livraison/point-de-livraison/conditionnement/liaisons/dialog/liaison-plc-mc-plc.component";
import {LiaisonsComponent} from "./points-de-livraison/point-de-livraison/conditionnement/liaisons/liaisons.component";
import {ReglesRegroupementComponent} from "./points-de-livraison/point-de-livraison/conditionnement/regles/regles-regroupement.component";
import {TabMenuModule} from "primeng/tabmenu";
import {SelectButtonModule} from 'primeng/selectbutton';

@NgModule({
    imports: [
        CommonModule,
        GestionClientsRoutingModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,

        TabMenuModule,
        ButtonModule,
        SelectButtonModule,
        DxDataGridModule,
        DxPopupModule,
        DxLookupModule,
        DxToolbarModule,
    ],
  declarations: [
    ClientComponent,
    ClientFicheIdentiteComponent,
    PointDeLivraisonComponent,
    PointDeLivraisonFicheIdentiteComponent,
    PointDeLivraisonConditionnementComponent,
    LiaisonPlcMcPlcComponent,
    GridClientsComponent,
    LiaisonsComponent,
    ReglesRegroupementComponent
  ]
})
export class GestionClientsModule { }
