<dx-popup
  [(visible)]="displayDialog"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
  height="auto"
  title="Calcul du coût de revient d'un plat"
>
  <div class="row">
    <div class="col-md-12">
      <dx-tree-view
        #tree
        [dataSource]="treePlc"
        [height]="450"
        keyExpr="id"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        [selectNodesRecursive]="true"
        [selectByClick]="true"
        [searchEnabled]="true"
        (onItemSelectionChanged)="onChangeSelectionRepas($event)"
        [searchEditorOptions]="{placeholder: 'Filtrer par point de livraison client'}"
        searchExpr="label"
        searchMode="contains"
        itemTemplate="itemTemplate"
      >
        <div *dxTemplate="let item of 'itemTemplate'">
          <i class="{{ item.icon }}" ></i> {{ item.label }}
        </div>
      </dx-tree-view>
    </div>
  </div>
  <div class="row mg-t-45">
    <div class="col-md-12">
      <label class="mg-r-15">Souhaitez-vous prendre en compte les mouvements de réajustement : </label>
      <input type="checkbox" name="takeAccountOfReajustMoves" [(ngModel)]="takeAccountOfReajustMoves" />
    </div>
  </div>
  <div class="row mg-t-45">
    <div class="col-md-12">
      <label class="mg-r-15">Veuillez sélectionner le type d'effectif sur lequel sera basé le coût de revient : </label>
      <p-radioButton name="effectif"
                     class="mg-b-5 mg-l-15"
                     [value]="TYPE_EFFECTIF.fabrication"
                     label="Effectif de fabrication"
                     [(ngModel)]="typeEffectif"
                     inputId="preopt2">
      </p-radioButton>
      <p-radioButton name="effectif"
                     class="mg-b-5 mg-l-15"
                     [value]="TYPE_EFFECTIF.facturation"
                     label="Effectif de facturation"
                     [(ngModel)]="typeEffectif"
                     inputId="preopt3">
      </p-radioButton>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <label class="mg-r-15">Sélection du jour de consommation : </label>
      <p-calendar
        firstDayOfWeek="1"
        class="mg-r-40"
        dateFormat="dd/mm/yy"
        selectionMode="single"
        [numberOfMonths]="2"
        [readonlyInput]="true"
        [(ngModel)]="dateProductionSelected"
        (onSelect)="changeDates($event)"
        (onMonthChange)="changeDates($event)"
        (onYearChange)="changeDates($event)"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true">
        <ng-template pTemplate="date" let-date>
          <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                         [dateCreationMenu]="utils.isDateInList(date,dateCreationMenu)"></yo-date-dispo>
        </ng-template>
      </p-calendar>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <label>Sélection de la période sur laquelle sera calculée le prix de revient :</label>
      <p-calendar [(ngModel)]="periodeSelected"
                  firstDayOfWeek="1"
                  [locale]="localeFr"
                  dateFormat="dd/mm/yy"
                  selectionMode="range"
                  [showWeek]="true"
                  [showIcon]="true"
                  [numberOfMonths]="2"
                  [readonlyInput]="true">
      </p-calendar>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <label class="mg-r-15">Exclure les coûts de sorties en mode denrée : </label>
      <input type="checkbox" name="exclureCoutModeDenree" [(ngModel)]="exclureCoutModeDenree" />
    </div>
  </div>
  <div class="row mg-t-15 float-right">
    <div class="col-xs-12">
      <span class="mg-r-5">
        <button pButton
                [disabled]="!canPrint()"
                type="button"
                icon="fas fa-print"
                (click)="printCoutRevient()"
                pTooltip="Imprimer le cout de revient du plat"
                label="Imprimer"
                showDelay="500">
        </button>
    </span>
    <span class="mg-r-5">
      <button type="button" class="cta-inner-delete" pButton icon="fas fa-times" (click)="closeDialog()"
              label="Fermer"
              pTooltip="Annuler"
              showDelay="500">
      </button>
    </span>
    </div>
  </div>
</dx-popup>


