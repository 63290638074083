import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {combineLatest, Subscription} from "rxjs";
import {
  McpConditionnementsVariantesService
} from "../../../core/services/conditionnements/mcp-conditionnements-variantes.service";
import {DxDataGridComponent} from "devextreme-angular";
import {ConviveDTO} from "../../../core/dtos/convive-dto";
import {ModelePlcParametrage, ModelesPlcService} from "../../../core/services/conditionnements/plc/modeles-plc.service";
import {ConvivesService} from "../../../core/services/entities/convives.service";
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from "../../../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto";
import {compact as _compact, groupBy as _groupBy} from 'lodash';
import {
  ModelesPlcPrestationsService
} from "../../../core/services/conditionnements/plc/conditionnement/prestation/modeles-plc-prestations.service";
import ModeleConditionnementPlc__PrestationDTO
  from "../../../core/dtos/conditionnement/plc/conditionnement/prestation/mc-plc-prestation-dto";
import ResteDTO from "../../../core/dtos/conditionnement/reste-dto";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";
import {FS_ROUTES, MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../core/constants";
import McPlc__McpCvDTO from "../../../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-mc-cv-dto";
import McPlcPrestation__McPlc__McpCvDTO
  from "../../../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-prestation-mc-plc-mc-cv-dto";
import {RestesService} from "../../../core/services/conditionnements/restes.service";
import {
  McPlcPrestaMcPlcMcCVService
} from "../../../core/services/conditionnements/plc/conditionnement/plat/mc-plc-presta-mplc-mp-cv.service";
import McPlcMcpCVService from "../../../core/services/conditionnements/plc/conditionnement/plat/mplc-mp-cv.service";
import {cloneDeep as _cloneDeep} from 'lodash';
import ModeleConditionnementPlcDTO from "../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {WizardService} from "../../../core/services/wizard-service";

@Component({
  selector: 'yo-wizard-configuration-step-9',
  templateUrl: './wizard-configuration-step-9.component.html',
  styleUrls: ['./wizard-configuration-step-9.component.scss']
})
export class WizardConfigurationStepNineComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  @ViewChild("grid") grid: DxDataGridComponent;

  private currentStep: number;

  prestationsList: ConviveDTO[] = [];

  prestationsSelectedList: ConviveDTO[] = [];

  mcpCvList: any[];

  mcpConditionnementVarianteList: ModeleConditionnementsPlatConditionnementsVariantesDTO[] = [];

  mcplcPrestations: ModeleConditionnementPlc__PrestationDTO[] = [];

  mcpCvSelectedList: any[];

  subCombine: Subscription;

  /**
   * Gestion des queues
   */
  queuesConfiguration: any = {};

  /**
   * Totalité des restes
   */
  resteList: ResteDTO[] = [{id: 1} as ResteDTO];

  /**
   *  Un trompe oeil pour DevExtreme pour avoir 1 footer d'une seule ligne.
   */
  footerList: any[] = [{id: 1}];

  /**
   * Mapping entre le n° de colonne DevExtreme et l'identifiant d'une prestation
   */
  mappingColumnsPrestations: Map<number, number> = new Map<number, number>();
  gridParametrage: any[];

  prestationsForGrid: ConviveDTO[] = [];

  mcPlcPrestaMcPlcCVList: McPlcPrestation__McPlc__McpCvDTO[] = [];

  mcPlcMcpCVList: McPlc__McpCvDTO[] = [];

  modelePlc: ModeleConditionnementPlcDTO;

  constructor(private auth2Svc: Auth2Service,
              private utilsSvc: UtilsService,
              private wizardSvc: WizardConditionnementService,
              private mcplcSvc: ModelesPlcService,
              private csSvc: ConvivesService,
              private mcpCvSvc: McpConditionnementsVariantesService,
              private mplcPrestaSvc: ModelesPlcPrestationsService,
              private mcPlcPrestaMcPlcCVSvc: McPlcPrestaMcPlcMcCVService,
              private resteSvc: RestesService,
              private mcPlcMcpCVSvc: McPlcMcpCVService,
              private i8nSvc: InternationalizationService,
              private toastSvc: ToastService,
              private wizardGlobalSvc: WizardService) {
    this.currentStep = this.wizardSvc.STEP_CONFIG_BINDINGS_GRID_MODELE_PLC;
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subCombine);
  }

  initData = (): void => {
    const dataStepEight: any = this.wizardSvc.getDataStepEight();
    const idModelePlc: number = dataStepEight.id;
    const idSelectedSite: number = dataStepEight.idSelectedSite;
    this.modelePlc = { id: idModelePlc } as ModeleConditionnementPlcDTO;

    this.mcPlcMcpCVSvc.getAllByIdMcplc(idModelePlc).subscribe((res) => {
      this.mcPlcMcpCVList = res.resultList || []; // combineLatest ne l'accepte pas en tatn que 7ème argument, sans aucune raison

      const allMcpCv$ = this.mcpCvSvc.getAllBySites([ idSelectedSite ]);
      const allPrestations$ = this.csSvc.findAll();
      const allMcpCvByMcplc$ = this.mcpCvSvc.getAllByIdMcplc(idModelePlc);
      const allMcplcPrestationsByMcplc$ = this.mplcPrestaSvc.getAllByIdMcplc(idModelePlc);
      const allRestes$ = this.resteSvc.getAll();
      const mcPlcPrestaMcPlcCVList$ = this.mcPlcPrestaMcPlcCVSvc.getAllByIdMcplc(idModelePlc);

      const combine$ = combineLatest([allMcpCv$, allPrestations$, allMcpCvByMcplc$, allMcplcPrestationsByMcplc$, allRestes$, mcPlcPrestaMcPlcCVList$]);

      this.subCombine = combine$.subscribe(response => {

        const allMcpCv = response[0].resultList || [];
        this.prestationsList = response[1].resultList || [];
        this.prestationsList = this.prestationsList.filter(p => p.actif).sort((p1, p2) => p1.libelle.localeCompare(p2.libelle));
        this.mcpConditionnementVarianteList = response[2].resultList || [];
        this.mcplcPrestations = response[3].resultList || [];
        this.resteList = response[4].resultList || [];
        this.mcPlcPrestaMcPlcCVList = response[5].resultList || [];


        let groupedMcpCv = this.initGroupingByMcp(allMcpCv);
        this.mcpCvList = groupedMcpCv;

        // On enlève les conditionnements variantes déjà configurés :
        this.initConditionnementsConfigured();

        // On enlève les prestations déjà configurées :
        this.initPrestationsConfigured();

        this.initGridParametrage();
      });
    });

  }

  private initGroupingByMcp = (allMcpCv: ModeleConditionnementsPlatConditionnementsVariantesDTO[]) => {
    const groupingByMcp = _groupBy(allMcpCv, (mcpCv: ModeleConditionnementsPlatConditionnementsVariantesDTO) => mcpCv.modeleConditionnementPlat.libelle);
    let groupedMcpCv = Object.keys(groupingByMcp).map(libelle => {
      const mcpCv = allMcpCv.find(m => m.modeleConditionnementPlat.libelle === libelle);
      if (mcpCv) {

        const items = groupingByMcp[mcpCv.modeleConditionnementPlat.libelle]
          .map(value => {
            return {
              label: `${value.conditionnementVariante.conditionnement.libelle} ${value.conditionnementVariante.variante.libelle}`,
              labelTag: `${mcpCv.modeleConditionnementPlat.libelle} - ${value.conditionnementVariante.variante.libelle}`,
              value: value.id
            }
          });

        return {key: mcpCv.modeleConditionnementPlat.libelle, value: mcpCv.id, items}
      }
      return null;
    });
    return _compact(groupedMcpCv);
  };

  /**
   * Initialise les conditionnements déjà configurés
   * @private
   */
  private initConditionnementsConfigured = (): void => {
    // On enlève les modèles conditionnement plat - conditionnements variantes déjà configurés :
    if (this.mcpConditionnementVarianteList && this.mcpConditionnementVarianteList.length) {
      const configList = this.initGroupingByMcp(this.mcpConditionnementVarianteList);

      this.mcpCvSelectedList = this.utilsSvc.flatDeep(
        configList.map(config => {

          const itemsSelected: any[] = [];
          this.mcpCvList.forEach(k =>
            k.items.forEach(item => itemsSelected.push(item))
          );

          const selectedList: any[] = itemsSelected.filter(item => config.items.filter(c => c.value === item.value).length > 0);
          return selectedList.map(s => s.value);
        })
      );
    } else {
      const itemsSelected: any[] = [];
      this.mcpCvList.forEach(k =>
        k.items.forEach(item => itemsSelected.push(item))
      );
      this.mcpCvSelectedList = itemsSelected.map(i => i.value);
    }
  };

  /**
   * Initialise les prestations déjà configurées
   * @private
   */
  private initPrestationsConfigured = (): void => {
    if (this.mcplcPrestations && this.mcplcPrestations.length) {
      const prestationsConfigureesList = this.mcplcPrestations.map(m => m.prestation);
      this.prestationsSelectedList = this.prestationsList.filter(pres => prestationsConfigureesList.filter(p => p.id === pres.id).length > 0);
    }
  };

  updateGridFromBindings = (): void => {
    this.mcplcSvc.saveRowParametrage(this.modelePlc, this.mcpCvSelectedList, this.prestationsSelectedList)
      .subscribe(() => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Grille configurée avec succès`);
        this.initData();
      });
  }

  getToolTipPrestation = cell => cell.row.data.prestations[cell.columnIndex][cell.row.data.id].actif ?
    'Cliquez ici pour désactiver la prestation' : 'Cliquez ici pour activer la prestation';

  onCellClick = (event: any) => {
    // 0 = MCP, 1 = Conditionnement, 2 = Variante
    if (event.columnIndex > 2) {
      event.data.prestations[event.columnIndex][event.data.id].actif = !event.data.prestations[event.columnIndex][event.data.id].actif;
      this.cellActiveBackground(event);
    }
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.lineHeight = '40px';
    }
  };

  onCellPrepared = (event: any) => {
    this.cellActiveBackground(event);
  };

  cellActiveBackground = (event: any) => {
    // 0 = MCP, 1 = Conditionnement, 2 = Variante
    if (event.columnIndex > 2 && event.rowType === DATAGRID_ROW_TYPES.DATA) {
      const cell = event.data.prestations[event.columnIndex][event.data.id];
      if (cell) {
        event.cellElement.style.cursor = 'pointer';
        if (cell.actif) {
          event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
        } else {
          event.cellElement.style.backgroundColor = UI_COLORS.NOT_CHECKED;
        }
      }
    }
  };


  private initGridParametrage = () => {
    if (this.mcpConditionnementVarianteList) {
      this.gridParametrage = this.mcpConditionnementVarianteList.map(mcv => {
        const current = this.mcPlcMcpCVList.find((m: McPlc__McpCvDTO) => {
          return m.mcpCv && m.mcpCv.id === mcv.id
        });

        const prestations = {};

        this.prestationsForGrid = this.prestationsList.filter(prestation => this.mcPlcPrestaMcPlcCVList.find((m: McPlcPrestation__McPlc__McpCvDTO) =>
          m.mcPlcMcpCv &&
          m.mcPlcMcpCv.mcpCv &&
          m.mcPlcMcpCv.mcpCv.id === mcv.id &&
          m.modeleConditionnementPlcPrestation.prestation &&
          m.modeleConditionnementPlcPrestation.prestation.id === prestation.id));

        this.prestationsForGrid.forEach((prestation, idx) => {
          const currentMcPlcPrestaMcPlcCV = this.mcPlcPrestaMcPlcCVList.find((m: McPlcPrestation__McPlc__McpCvDTO) =>
            m.mcPlcMcpCv &&
            m.mcPlcMcpCv.mcpCv &&
            m.mcPlcMcpCv.mcpCv.id === mcv.id &&
            m.modeleConditionnementPlcPrestation.prestation &&
            m.modeleConditionnementPlcPrestation.prestation.id === prestation.id
          );

          // + 3 car les premières colonnes sont mcp, conditionnement et variante :
          if (currentMcPlcPrestaMcPlcCV) {
            const key = idx + 3;
            this.mappingColumnsPrestations.set(key, prestation.id);
            // prestations => objet dont la clé est l'association de l'identifiant d'une mcp condition variante et d'une prestation.
            // En effet, chaque valeur prescrite dans une cellule est faite pour une prestation d'une mcp condition variante.
            prestations[key] = {
              [mcv.id]: { // identifiant pour représenter la ligne de l'ihm
                mcplc__prestaid: currentMcPlcPrestaMcPlcCV.modeleConditionnementPlcPrestation.id,
                mcplc__mcpCvid: currentMcPlcPrestaMcPlcCV.mcPlcMcpCv.id,
                mcPlcPrestaMcPlcMcpCvId: currentMcPlcPrestaMcPlcCV.id, // Identifiant à utiliser pour le backend
                actif: currentMcPlcPrestaMcPlcCV.actif ? currentMcPlcPrestaMcPlcCV.actif : false
              }
            }
          }
        });

        const result = {
          id: mcv.id,
          mcpLabel: mcv.modeleConditionnementPlat ? mcv.modeleConditionnementPlat.libelle : '',
          conditionnementLabel: mcv.conditionnementVariante && mcv.conditionnementVariante.conditionnement ? mcv.conditionnementVariante.conditionnement.libelle : '',
          varianteLabel: mcv.conditionnementVariante && mcv.conditionnementVariante.variante ? mcv.conditionnementVariante.variante.libelle : 'Aucune',
          idmcPlcMcpCV: current.id,
          prestations
        };

        return (result);
      });
    }
  };

  previous = (): void => {
    this.onEmitPreviousStep.emit({currentStep: this.currentStep});
  }

  next = (): void => {
    const grid = _cloneDeep(this.gridParametrage);
    const model: ModelePlcParametrage = this.mcplcSvc.formatDataParametrage(grid, this.mappingColumnsPrestations, this.queuesConfiguration, this.modelePlc?.id);
    this.onEmitNextStep.emit({
      currentStep: this.currentStep,
      prestationsSelected: this.prestationsSelectedList.map(p => p.id),
      model
    });
  }

  currentStepPourcentage = (): number => this.wizardGlobalSvc.calculateCurrentStepPourcentage(this.wizardSvc.totalSteps, this.currentStep);

}
