<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>

  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="dialog-line">
      <div class="label" pTooltip="Libellé">Site <span class="danger-color">*</span></div>
      <div class="value">
        <dx-lookup
          [dataSource]="findAllLocalSites()"
          [value]="idSelectedSite"
          valueExpr="id"
          displayExpr="libelle"
          [searchEnabled]="true"
          [placeholder]="sitePlaceholder"
          (onSelectionChanged)="onChangeSite($event)"
          [disabled]="!canModify()">
          <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
        </dx-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label">Couleur <span class="danger-color">*</span></div>
      <div class="value">
        <div [formGroup]="form">
          <dx-lookup
            [dataSource]="colors"
            [value]="idSelectedColor"
            valueExpr="id"
            displayExpr="libelle"
            [searchEnabled]="true"
            placeholder="Selectionner la couleur..."
            itemTemplate="listItem"
            fieldTemplate="field"
            (onSelectionChanged)="onChangeColor($event)"
            [disabled]="!canModify()">
            <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>

            <div *dxTemplate="let couleur of 'listItem'">
              <div class="listColorContainer">
                <yo-couleur [tooltip]="couleur?.code"
                            [hexColor]="couleur?.hexa"
                            class="mg-r-5 listColorElement">
                </yo-couleur>
                <div class="listLibelleElement">{{couleur?.libelle}}</div>
              </div>
            </div>
            <div *dxTemplate="let couleur of 'field'">
              <yo-couleur [tooltip]="getTooltipValue(getCurrentColor()?.code)"
                          [hexColor]="getCurrentColor()?.hexa"
                          class="mg-r-5 fieldColor"></yo-couleur>
              <span class="fieldLabel">{{getCurrentColor()?.libelle}}</span>
            </div>
          </dx-lookup>

        </div>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" pTooltip="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box placeholder="Libellé" formControlName="libelle" [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" pTooltip="Code">Code <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box placeholder="Code" formControlName="code" [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" pTooltip="Ordre">Ordre <span class="danger-color">*</span></div>
      <div class="value">
        <dx-number-box placeholder="Ordre" formControlName="ordre" [disabled]="!canModify()"></dx-number-box>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" pTooltip="Actif">Actif <span class="danger-color">*</span></div>
      <div class="value">
        <dx-check-box [value]="true" formControlName="actif" [disabled]="!canModify()"></dx-check-box>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        pButton type="submit"
        icon="fas fa-save"
        label="Enregistrer"
        class="p-button-success"
        [hidden]="!canModify()">
      </button>
      <button
        class="mg-l-5 cta-delete"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times">
      </button>
    </div>
  </form>

</dx-popup>
