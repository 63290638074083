<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Alimentation du référentiel</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de gérer à travers cette étape vos différents conditionnements et vos différentes variantes pour le site local <yo-site *ngIf="currentSite" [siteLibelle]="currentSite.libelle" [siteId]="currentSite.id"></yo-site>. Cette étape est facultative dans le cas où
        vous disposez déjà de conditionnements et de variantes. La modification d'informations se fait directement sur le tableau en cliquant sur la cellule de votre choix.
        L'ajout d'un nouveau référentiel se réalise en appuyant sur le bouton + en haut à droite du tableau concerné, une ligne apparaît alors pour la compléter. Le référentiel des conditionnements se trouve dans le tableau de gauche,
        le référentiel des variantes se trouve dans le tableau de droite. La colonne "Poids en Kg" du tableau de gauche représente le poids théorique du conditionnement vide en vue d'évaluer le poids total sur essieux de la livraison.
      </p>
      <div class="row">
        <div class="col-lg-6 container-table">
          <dx-data-grid
            [dataSource]="conditionnements"
            keyExpr="id"
            width="100%"
            height="100%"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true"
            (onRowInserting)="onRowInsertingConditionnement($event)"
            (onRowInserted)="onRowInsertedConditionnement($event)"
            (onRowUpdated)="onRowUpdatedConditionnement($event)"
            #grid>
            <dxo-editing
              mode="cell"
              [allowAdding]="true"
              [allowDeleting]="false"
              [allowUpdating]="true"
            ></dxo-editing>
            <dxi-column dataField="libelle" caption="Libellé"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true">
            </dxi-column>
            <dxi-column dataField="abreviation" caption="Abréviation"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true">
            </dxi-column>
            <dxi-column dataField="poidsEnKg" caption="Poids en Kg"
                        dataType="number"
                        [filterOperations]="['contains']"
                        [allowFiltering]="true">
            </dxi-column>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"></dxo-sorting>

            <dxo-pager
              [showPageSizeSelector]="true"
              [showNavigationButtons]="false"
              [allowedPageSizes]="[20, 50, 100, 200]"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} conditionnements">
            </dxo-pager>

          </dx-data-grid>
        </div>
        <div class="col-lg-6 container-table">
          <dx-data-grid
            [dataSource]="variantes"
            keyExpr="id"
            width="100%"
            height="100%"
            [hoverStateEnabled]="true"
            [allowColumnResizing]="true"
            [rowAlternationEnabled]="true"
            [showRowLines]="true"
            [showBorders]="true"
            (onRowInserting)="onRowInsertingVariante($event)"
            (onRowInserted)="onRowInsertedVariante($event)"
            (onRowUpdated)="onRowUpdatedVariante($event)"
            #grid2>
            <dxo-editing
              mode="cell"
              [allowAdding]="true"
              [allowDeleting]="false"
              [allowUpdating]="true"
            ></dxo-editing>
            <dxi-column dataField="libelle" caption="Libellé" [width]="100"
                        [filterOperations]="['contains']"
                        [allowEditing]="true"
                        [allowFiltering]="true">
            </dxi-column>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"></dxo-sorting>

            <dxo-pager
              [showPageSizeSelector]="true"
              [showNavigationButtons]="false"
              [allowedPageSizes]="[20, 50, 100, 200]"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} variantes">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 3/13 : Alimentation du référentiel - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
