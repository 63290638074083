<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <form (ngSubmit)="save()">

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Site <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-lookup
          [dataSource]="findAllLocalSites()"
          [value]="idSelectedSite"
          valueExpr="id"
          displayExpr="libelle"
          [searchEnabled]="true"
          [placeholder]="sitePlaceholder"
          (onSelectionChanged)="onChangeSite($event)"
          [disabled]="!canModify()"
        >
          <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
        </dx-lookup>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Libellé <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-text-box placeholder="Libellé" [(ngModel)]="libelle" name="libelle" [disabled]="!canModify()"></dx-text-box>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Description">Description <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-text-box placeholder="Description" [(ngModel)]="description" name="description" [disabled]="!canModify()"></dx-text-box>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Est-ce une entrée de stock ?">Est-ce-que cela concerne une entrée de stock ? <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-check-box [(ngModel)]="entree" name="entree" [disabled]="!canModify()"></dx-check-box>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Description">Est-ce-que cela concerne une sortie de stock ? <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-check-box [(ngModel)]="sortie" name="sortie" [disabled]="!canModify()"></dx-check-box>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Modifiable / Supprimable ?">Modifiable / Supprimable ? <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <dx-check-box [(ngModel)]="modifiable" name="modifiable" [disabled]="!canModify()"></dx-check-box>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mg-t-10">
      <button
        pButton type="submit"
        icon="fas fa-save"
        class="p-button-success"
        label="Enregistrer"
        [disabled]="!canModify()"
        [class.disable]="!canModify()">
      </button>
      <button
        class="mg-l-5 cta-inner-delete"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>

</dx-popup>


