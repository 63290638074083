<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Sélection des points de livraison client</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de gérer à travers cette étape la sélection des points de livraison client qui vont concerner votre conditionnement.
        Attention, un point de livraison client ne peut être lié qu'à un seul modèle de point de livraison.
      </p>
    </div>
    <div class="row mg-t-10">
      <div class="col-lg-12 container-table">
        <dx-data-grid
          [dataSource]="pointsLivraisons"
          keyExpr="keyPlcPrestation"
          height="100%"
          width="100%"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          [showRowLines]="true"
          [showBorders]="true"
          [(selectedRowKeys)]="selectedRows"
          #grid>

          <dxo-selection [deferred]="false" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>

          <dxi-column dataField="libelleSite" caption="Site" cellTemplate="siteCellTemplate" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="libelleClient" caption="Client" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="libelle" caption="Point de livraison client" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="offreAlimentaire.libelle" caption="Offre alimentaire" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="prestation.libelle" caption="Prestation" [allowFiltering]="true"></dxi-column>

          <div *dxTemplate="let cell of 'siteCellTemplate'">
            <yo-site [siteId]="convertNumber(cell.row.data.idSite)" [siteLibelle]="cell.row.data.libelleSite"
                     [yoDelayMsTooltip]="1500"></yo-site>
          </div>

          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-sorting mode="multiple"></dxo-sorting>
          <dxo-paging [pageSize]="50"></dxo-paging>

          <dxo-pager
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[20, 50, 100]"
            [showNavigationButtons]="false"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} point(s) de livraison client">
          </dxo-pager>

        </dx-data-grid>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    Etape 10/13 : Sélection des points de livraison client - {{ currentStepPourcentage() }} %
  </div>
  <div class="col-lg-12 no-padding text-center mg-b-5">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" [style.width.%]="currentStepPourcentage()"></div>
    </div>
  </div>
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
