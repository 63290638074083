<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [style]="{'width':'1000px'}"
  [modal]="true"
  [breakpoint]="640"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sign-out-alt mg-r-5"></i> Sortir le lot {{selectedStock?.lotArticle}}
      ({{selectedStock?.produitArticleLibelle}})
    </div>
  </ng-template>

  <ng-container *ngIf="!utils.isNullOrEmpty(selectedStock)">


    <div class="row">

      <!--          Fiche identité -->
      <div class="col-md-6">

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Unité de production</div>
          <div class="col-md-8"><strong> <i class="fas fa-industry mg-r-5"></i>{{selectedStock.udpLibelle}}
          </strong></div>
        </div>

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Zone de stockage</div>
          <div class="col-md-8"><strong> <i class="fas fa-cubes mg-r-5"></i>{{selectedStock.zdsLibelle}}
          </strong></div>
        </div>

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Réf. interne</div>
          <div class="col-md-8"><strong> {{selectedStock.produitArticleReference}}</strong></div>
        </div>


        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Dénomination</div>
          <div class="col-md-8"><strong>{{selectedStock.produitArticleLibelle}}</strong></div>
        </div>

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Lot</div>
          <div class="col-md-8"><strong>{{selectedStock.lotArticle}}</strong></div>
        </div>


        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Lot fournisseur</div>
          <div class="col-md-8"><strong>{{selectedStock.lotArticleFournisseur}}</strong></div>
        </div>

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Date d'entrée</div>
          <div class="col-md-8"><strong>{{selectedStock.dateEntree  | date:'dd/MM/yyyy'}}</strong></div>
        </div>

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">DLC</div>
          <div class="col-md-8">
            <strong class="pg-4"
                    [class.perime]="stockSvc.isPerime(selectedStock.dlc, filters)">{{selectedStock.dlc  | date:'dd/MM/yyyy'}}</strong>
          </div>
        </div>

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel " title="En unité de stockage" showDelay="500">Quantité actuelle</div>
          <div class="col-md-8 "><strong
            class="bg-prefere-color white pg-4">{{selectedStock.quantite}} {{selectedStock.uniteDeStockage}}</strong>
          </div>
        </div>

        <div class="row mg-t-10">
          <div class="col-md-4 genlabel " title="En unité de facturation" showDelay="500">Prix</div>
          <div class="col-md-8"><strong>{{selectedStock.prixUnitaire | currency:'EUR':'symbol':'1.4-4' }}
            / {{selectedStock.uniteFacturation}}</strong></div>
        </div>

      </div>

      <!--actions-->

      <div class="col-md-6">
        <form [formGroup]="form">
          <div class="row mg-t-10">
            <div class="col-md-4 genlabel required">Quantité à sortir</div>
            <div class="col-md-8">

              <input pInputText pKeyFilter="pnum"
                     [style.width.px]="80"
                     min="0"
                     (keydown)="utils.keydownNumber($event,form,'quantite',true)"
                     formControlName="quantite"
                     class=" text-right bold">

              <ng-container
                *ngTemplateOutlet="tplError;context:{form:form,property:'quantite',label:'Quantité à sortir'}"></ng-container>

            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-4 genlabel required">Date de sortie</div>
            <div class="col-md-8">
              <p-calendar
                firstDayOfWeek="1"
                dateFormat="DD d MM yy"
                tooltipPosition="bottom"
                showDelay="500"
                [style]="{'font-weight':'bold'}"
                [readonlyInput]="true"
                formControlName="dateSortie"
                [locale]="localeFr"
                [showIcon]="true"
                [showWeek]="true">
              </p-calendar>
              <ng-container
                *ngTemplateOutlet="tplError;context:{form:form,property:'dateSortie',label:'Date de la sortie'}"></ng-container>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-12 genlabel required">Motif de la sortie</div>
          </div>
          <div class="row ">
            <div class="col-md-12">
              <p-listbox
                [style]="{'width':'100%','margin-bottom':'0px','font-size': '14px'}"
                [listStyle]="{'max-height':'150px'}"
                [options]="mouvementStatutList"
                formControlName="motif"
                optionLabel="libelle"></p-listbox>
              <ng-container
                *ngTemplateOutlet="tplError;context:{form:form,property:'motif',label:'Motif de la sortie'}"></ng-container>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-12 genlabel">Remarque</div>
          </div>
          <div class="row ">
            <div class="col-md-12">
              <textarea pInputTextarea style="width:100%;height:80px;"
                        formControlName="remarque"
              ></textarea>
              <ng-container
                *ngTemplateOutlet="tplError;context:{form:form,property:'remarque',label:'Remarque'}"></ng-container>
            </div>
          </div>

        </form>
      </div>

    </div>


  </ng-container>

  <ng-template pTemplate="footer">



    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-sign-out-alt" (click)="sortirLot()" title="Sortir un lot du stock"
              label="Sortir le lot"
              showDelay="500"
      ></button>
    </span>
    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-times" (click)="closeDialog()" title="Annuler" label="Annuler"
              class="cta-inner-delete"
              showDelay="500"></button>
    </span>


  </ng-template>

</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      {{startError(label)}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      {{startError(label)}} ne peut avoir moins de {{form.controls[property].errors.minlength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      {{startError(label)}} ne peut dépasser {{form.controls[property].errors.maxlength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('min')">
      {{startError(label)}} doit être supérieur ou égal à {{form.controls[property].errors.min.min}}.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('max')">
      {{startError(label)}} doit être inférieur ou égal à {{form.controls[property].errors.max.max}}.
    </div>

  </div>

</ng-template>

